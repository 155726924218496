import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConfigService } from './config.service';
import { DecimalPipe } from '@angular/common';
import { ChartDataSets, ChartOptions, ChartPoint } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TempHistory } from './temp-history';
import { Observable } from 'rxjs';
import { KalmanFilter } from './kalman-filter';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  kalmanFilters: KalmanFilter[];

  public lineChartData: ChartDataSets[] = [
    { data: [], label: 'Probe 1' },
    { data: [], label: 'Probe 2' }
  ];

  public lineChartLabels: Label[] = [];

  public rateChartData: ChartDataSets[] = [
    { data: [], label: 'Probe 1' },
    { data: [], label: 'Probe 2' }
  ];

  public rateChartLabels: Label[] = [];
  private datePipe = new DatePipe('en-US');

  constructor(public configService: ConfigService, private decimalPipe: DecimalPipe,
              private http: HttpClient) {

    try {
      const settingsObs = this.configService.settings.subscribe({
        next: settings => {
          if (settings.tempUnit) {
            this.lineChartData.forEach((ar) => {
              this.updateChartUnit(ar.data);
            });
          }
        }
      });
    } catch (e) {
      console.log(`Caught error: ${e}`);
    }
  }

  getTemp(celsius: number): string {
    console.log(this.configService.getTempUnit());

    let t = celsius;

    if (this.configService.getTempUnit() === 'F') {
      t = this.cToF(celsius);
    }

    return this.decimalPipe.transform(t, '1.1-1'); // Convert to formattd string.
  }

  addTemp(celsius: number, probeNum: number) {
    const temp = this.getTemp(celsius);
    const tData = this.lineChartData[probeNum - 1].data;

    if (tData.length > environment.maxGraphPoints) {
      setTimeout(() => tData.shift(), 0);
    }

    tData.push(Number(temp));
  }

  addRate(r: number, probeNum: number) {
    const rData = this.rateChartData[probeNum - 1].data;
    if (rData.length > environment.maxGraphPoints) {
      setTimeout(() => rData.shift(), 0);
    }
    rData.push(Number(r));
  }

  addLabel(dt: any) { // Add temp label
    if (this.lineChartLabels.length > environment.maxGraphPoints) {
      setTimeout(() => this.lineChartLabels.shift(), 0);
    }
    const fDate = this.formatDate(dt);
    this.lineChartLabels.push(fDate);
  }

  addRLabel(dt: any) {
    if (this.rateChartLabels.length > environment.maxGraphPoints) {
      setTimeout(() => this.rateChartLabels.shift(), 0);
    }
    this.rateChartLabels.push(this.formatDate(dt));
  }

  fToC(f: number): number {
    const t = (f - 32) * 5 / 9; // Convert F to C.
    return Number(this.decimalPipe.transform(t, '1.1-1')); // Round
  }

  cToF(c: number): number {
    const t = c * (9 / 5) + 32; // Convert C to F.
    return Number(this.decimalPipe.transform(t, '1.1-1')); // Round
  }

  updateChartUnit(ar: any[]) {

    if (this.configService.getTempUnit() === 'F') {
      ar.forEach((t, idx) => {
        ar[idx] = this.cToF(t);
      });
    } else {
      ar.forEach((t, idx) => {
        ar[idx] = this.fToC(t);
      });
    }
  }

  getTempHistory(): Observable<TempHistory[]> {
      return this.http.get<TempHistory[]>(
        `${environment.srApiBaseUrl}/gettemphistory?key=${environment.srAapiKey}&`
        + `deviceId=${this.configService.getCurrentDevice().deviceId}`
      );
  }

  addTempHistory(data: TempHistory[]) {
    console.log('Adding temp history...');

    // Reset chart data back to null.
    this.lineChartData[0].data = [];
    this.lineChartData[1].data = [];
    this.lineChartLabels = [];

    data.forEach((t: TempHistory) => {
      let avg_temp1 = t.avg_temp1;
      let avg_temp2 = t.avg_temp2;

      if (this.configService.alertConfig.probes.Probe1.kalman && this.configService.alertConfig.probes.Probe1.kalmanR &&
        this.configService.alertConfig.probes.Probe1.kalmanQ) {
        avg_temp1 = this.kalmanFilters[0].filter(avg_temp1);
      }

      if (this.configService.alertConfig.probes.Probe2.kalman && this.configService.alertConfig.probes.Probe2.kalmanR &&
        this.configService.alertConfig.probes.Probe2.kalmanQ) {
        avg_temp2 = this.kalmanFilters[1].filter(avg_temp2);
      }

      this.addTemp(Number(avg_temp1), 1);
      this.addTemp(Number(avg_temp2), 2);
      this.addLabel(new Date(t.date_ms));
    });
  }

  formatDate(dt: Date) {
    const fDate = this.datePipe.transform(dt, 'mediumTime');
    return fDate;
  }

  kalmanInit() {
    console.log('initialising kalman filters...');
    this.kalmanFilters = [
      new KalmanFilter({R: this.configService.alertConfig.probes.Probe1.kalmanR, Q: this.configService.alertConfig.probes.Probe1.kalmanQ}),
      new KalmanFilter({R: this.configService.alertConfig.probes.Probe2.kalmanR, Q: this.configService.alertConfig.probes.Probe2.kalmanQ})
    ]; // Create a filter for each probe.
  }
  getTempTargetPrediction(rate: number, currTemp: number, targetTemp: number): number {
    const diff = targetTemp - currTemp;
    return (diff / rate) / 60;
  }
}
