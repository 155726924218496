import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, DocumentData  } from '@angular/fire/firestore';
import { LoginService } from './login.service';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { TempDevice } from './temp-device';
import { Resolve } from '@angular/router';
import { AlertConfig } from './alert-config';
import { ChartOptions } from 'chart.js';
import { take } from 'rxjs/operators';

export interface Item { name: string; }

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements Resolve<[TempDevice, AlertConfig]> {
  // Kalman settings
  tempUnit = 'C';
  devices: TempDevice[];
  currentDevice: TempDevice;
  settingsDoc: AngularFirestoreDocument;
  settings: Observable<DocumentData>;
  alertsDoc: AngularFirestoreDocument;
  alerts: Observable<DocumentData>;
  alertConfig: AlertConfig;
  lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      yAxes: [{
        scaleLabel: {
            display: true,
            labelString: 'Temperature'
        }
      }],
      xAxes: [{
        scaleLabel: {
            display: true,
            labelString: 'Time'
        }
      }]
    },
    annotation: {
      annotations: []
    }
  };

  constructor(private afs: AngularFirestore, private loginService: LoginService) {
      const uid = this.loginService.getUid();
      this.settingsDoc = this.afs.doc<AngularFirestoreDocument>(`user-settings/${uid}`);
      this.settings = this.settingsDoc.valueChanges();
  }

  resolve(): Observable<any> {
    console.log('Resolving stuff...');

    return this.getCurrentDevice2().pipe(
        concatMap((res: TempDevice) => {
          console.log('Resolving device alerts...');
          return this.getAlertConfig(res.deviceId);
        })
    );
  }

  doAnnotations() {
    this.lineChartOptions.annotation.annotations = [];
    if (this.alertConfig.probes.Probe1.active) {
      this.lineChartOptions.annotation.annotations.push({
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: this.alertConfig.probes.Probe1.max,
        borderColor: 'red',
        borderWidth: 1,
        label: {
          enabled: true,
          fontSize: 10,
          content: `Max`,
          position: 'left',
          xPadding: 3,
          yPadding: 3,
          yAdjust: 6
        }
      });

      this.lineChartOptions.annotation.annotations.push({
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: this.alertConfig.probes.Probe1.min,
        borderColor: 'red',
        borderWidth: 1,
        label: {
          enabled: true,
          fontSize: 10,
          content: `Min`,
          position: 'left',
          xPadding: 3,
          yPadding: 3,
          yAdjust: -6
        }
      });
    }
    if (this.alertConfig.probes.Probe2.active) {
      this.lineChartOptions.annotation.annotations.push({
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: this.alertConfig.probes.Probe2.max,
        borderColor: 'blue',
        borderWidth: 1,
        label: {
          enabled: true,
          fontSize: 10,
          content: `Max`,
          position: 'right',
          xPadding: 3,
          yPadding: 3,
          yAdjust: 6
        }
      });

      this.lineChartOptions.annotation.annotations.push({
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: this.alertConfig.probes.Probe2.min,
        borderColor: 'blue',
        borderWidth: 1,
        label: {
          enabled: true,
          fontSize: 10,
          content: `Min`,
          position: 'right',
          xPadding: 3,
          yPadding: 3,
          yAdjust: -6
        }
      });
    }
  }

  getTempUnit(): string {
    return this.alertConfig.units;
  }

  private updateCurrentDevice(deviceId: string) {
    this.settingsDoc.update({currentDeviceId: deviceId});
  }

  setCurrentDevice(deviceId: string) {
    console.log('Setting current device...');
    let deviceFound = false;

    this.devices.forEach(device => {
      if (deviceId === device.deviceId) {
        this.currentDevice = device;
        deviceFound = true;
      }
    });

    if (!deviceFound) {
      console.log(`Device id: ${deviceId} was not found in devices array.`);
    } else {
      this.updateCurrentDevice(deviceId);
    }
  }

  public getCurrentDevice(): TempDevice {
      return this.currentDevice;
  }

  public getCurrentDevice2(): Observable<TempDevice> {
    try {
      console.log('getCurrentDevice2...');

      const ob = new Observable<TempDevice>(observer => {
        if (this.currentDevice) {
          observer.next(this.currentDevice);
          observer.complete();
        } else {
          this.settings.pipe(take(1)).subscribe({
            next: settings => {
              console.log('Settings retrieved...');
              this.devices = settings.devices;
              if (!settings.currentDeviceId) {
                try {
                  this.setCurrentDevice(settings.devices[0].deviceId);
                } catch (e) {
                  console.error(`There was an error getting devices. ${e}`);
                }
              } else {
                this.setCurrentDevice(settings.currentDeviceId);
              }
              observer.next(this.currentDevice);
              observer.complete();
            }
          });
        }
      });

      return ob;
    } catch (e) {
      console.error(`An error occured getting current device. ${e}`);
    }
  }

  public getAlertConfig(devId: string): Observable<AlertConfig> {
    try {
      console.log('getAlertConfig...');

      const ob = new Observable<AlertConfig>(observer => {
        if ((this.alertConfig) && (this.currentDevice.deviceId === devId)) {
          observer.next(this.alertConfig);
          observer.complete();
        } else {
          // init alerts
          this.alertsDoc = this.afs.doc<AngularFirestoreDocument>(`temp-range/${devId}`);
          this.alerts = this.alertsDoc.valueChanges();
          this.alerts.pipe(take(1)).subscribe({
            next: (item: any) => {
              try {
                console.log(`Alert config: ${item.probes.Probe1.name}`);
                this.alertConfig = item;
                observer.next(this.alertConfig);
                observer.complete();
              } catch (e) {
                console.error('Caught error:' + e);
              }
            },
            error(msg) {
              console.log('Error Getting settings: ', msg);
            }
          });
        }
      });

      return ob;

    } catch (e) {
      console.error(`An error occured getting alertConfig. ${e}`);
    }
  }
}
