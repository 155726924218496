import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, throwError } from 'rxjs';
import { ChartOptions } from 'chart.js';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { Color } from 'ng2-charts';
import { MessagingService } from '../messaging.service';
import { LoginService } from '../login.service';
import { DataService } from '../data.service';
import { ConfigService } from '../config.service';
import { environment } from '../../environments/environment';
import { KalmanFilter } from '../kalman-filter';
import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';

export interface Item {
  deviceId: string;
  temp: number;
  temp2: number;
  temp3: number;
  temp4: number;
  temp5: number;
  updated: any;
}

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit, OnDestroy {
  uid = '';
  itemSubscription: any;
  devices: any;
  device: any;
  message: any;

  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      yAxes: [{
        scaleLabel: {
            display: true,
            labelString: 'Temperature'
        }
      }],
      xAxes: [{
        scaleLabel: {
            display: true,
            labelString: 'Time'
        }
      }]
    },
    annotation: {
      annotations: []
    }
  };

  public lineChartColors: Color[] = [
    {
      borderColor: 'grey',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  public item: Observable<Item>;
  public itemDoc: AngularFirestoreDocument<Item>;
  public deviceId = '';
  public temp1 = '0';
  public temp2 = '0';
  public temp5 = '0';
  public updateDate;
  public updateDateString = '';


  // Rate change graph
  public interval = environment.rateChangeInterval;
  public ratetemp1 = '0';
  public ratetemp2 = '0';
  rate1: number;
  rate2: number;

  public rateChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      yAxes: [{
        scaleLabel: {
            display: true,
            labelString: 'Temp change'
        }
      }],
      xAxes: [{
        scaleLabel: {
            display: true,
            labelString: 'per ' + this.interval * 10 + 's'
        }
      }]
    }
  };

  public rateChartColors: Color[] = [
    {
      borderColor: 'grey',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public rateChartLegend = true;
  public rateChartType = 'line';
  public rateChartPlugins = [];
  // end rate change graph

  constructor(private db: AngularFirestore, private msgService: MessagingService,
              private loginService: LoginService, private dataService: DataService,
              public configService: ConfigService) {
                console.log('Constructor running...');
                this.configService.doAnnotations();
  }

  ngOnInit() {
    console.log('Running oninit...');
    this.dataService.kalmanInit(); // init kalman filter for each probe.
    this.lineChartOptions = this.configService.lineChartOptions;
    this.device = this.configService.getCurrentDevice();
    this.msgService.requestPermission(this.loginService.getUid());
    this.msgService.receiveMessage();
    this.message = this.msgService.currentMessage;
    this.uid = this.loginService.getUid();

    console.log('UID: ' + this.uid);
    if (this.device) {
      this.initTempUpdate();

      console.log('Getting temp history...');
      this.dataService.getTempHistory().subscribe({
        next: item => {
          try {
            console.log('Temp history received.');
            this.dataService.kalmanInit(); // init kalman filter for each probe.
            this.dataService.addTempHistory(item);
          } catch (e) {
            console.error(`There was an error: ${e}`);
          }
        },
        error(msg) {
          console.error('Error Getting data: ', msg);
        }
      });
    }

    try {
      this.device = this.configService.getCurrentDevice();
      console.log(`Current device: ${this.device.deviceId}`);
      this.initTempUpdate();
    } catch (e) {
      console.log(`Caught error: ${e}`);
    }
  }

  ngOnDestroy() {
    console.log('Running OnDestroy...');
    this.itemSubscription.unsubscribe();
  }

  private initTempUpdate() {
    console.log('Init temp updates...');
    if (this.itemSubscription) {
      this.itemSubscription.unsubscribe();
    }
    this.itemDoc = this.db.doc<Item>(`/temp-readings/${this.device.deviceId}`);

    this.item = this.itemDoc.valueChanges();
    this.itemSubscription = this.item.subscribe({
      next: item => {
        try {
          console.log(item);
          let temp = item.temp;
          let temp2 = item.temp2;
          const temp5 = item.temp5;

          if (this.configService.alertConfig.probes.Probe1.kalman && this.configService.alertConfig.probes.Probe1.kalmanR &&
            this.configService.alertConfig.probes.Probe1.kalmanQ) {
            temp = this.dataService.kalmanFilters[0].filter(temp);
            console.log(`Kalman Q is: ${this.configService.alertConfig.probes[`Probe1`].kalmanQ} and \
            R is: ${this.configService.alertConfig.probes[`Probe1`].kalmanR}`);
            console.log(`Original reading: ${item.temp} and Kalman: ${temp}`);
          }

          if (this.configService.alertConfig.probes.Probe2.kalman && this.configService.alertConfig.probes.Probe2.kalmanR &&
            this.configService.alertConfig.probes.Probe2.kalmanQ) {
            temp2 = this.dataService.kalmanFilters[1].filter(temp2);
            console.log(`Kalman Q is: ${this.configService.alertConfig.probes[`Probe2`].kalmanQ} and \
            R is: ${this.configService.alertConfig.probes[`Probe2`].kalmanR}`);
            console.log(`Original reading: ${item.temp2} and Kalman: ${temp2}`);
          }

          this.deviceId = item.deviceId;
          this.temp1 = this.dataService.getTemp(temp); // Converts to F if needed.
          this.temp2 = this.dataService.getTemp(temp2); // Converts to F if needed.
          this.temp5 = this.dataService.getTemp(temp5); // Converts to F if needed.
          this.updateDate = item.updated.toDate();
          this.updateDateString = item.updated.toDate().toLocaleString();
          this.dataService.addTemp(Number(temp), 1);
          this.dataService.addTemp(Number(temp2), 2);
          this.dataService.addLabel(item.updated.toDate());

          // Rate change
          this.ratetemp1 = this.dataService.getTemp(item.temp); // Converts to F if needed.
          this.ratetemp2 = this.dataService.getTemp(item.temp2); // Converts to F if needed.
          const interval = this.interval;
          const t1 = this.dataService.lineChartData[0].data[this.dataService.lineChartData[0].data.length - interval];
          console.log(t1);
          const t2 = parseFloat(this.temp1);
          console.log(t2);
          this.rate1 = (t2 - Number(t1)) / interval;
          console.log(this.rate1);

          const t3 = this.dataService.lineChartData[1].data[this.dataService.lineChartData[1].data.length - interval];
          console.log(t3);
          const t4 = parseFloat(this.temp2);
          this.rate2 = (t4 - Number(t3)) / interval;
          this.dataService.addRate(this.rate1, 1);
          this.dataService.addRate(this.rate2, 2);
          this.dataService.addRLabel(item.updated.toDate());
        } catch (e) {
          console.log(e);
        }
      },
      error(msg) {
        console.log('Error Getting data: ', msg);
      }
    });
  }

  public hasTemp(temp) {
    if ((this.configService.getTempUnit() === 'F') && (temp > 32) || (this.configService.getTempUnit() === 'C') && (temp > 0)) {
      return true;
    } else {
      return false;
    }
  }

}
