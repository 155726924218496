import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';

export interface Item { name: string; }

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  settingsDoc: any;
  settingsSnapshot: any;

  constructor(private router: Router,
              private loginService: LoginService) { }

  ngOnInit() {

  }

  onLogin($event: any) {
    this.loginService.setUser($event);
    this.loginService.setUid($event.uid); // Set userId
    this.router.navigateByUrl('/monitor');
    const projectId = 'yourlocalgoods';
  }
}
