import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MonitorComponent } from './monitor/monitor.component';
import { AboutComponent } from './about/about.component';
import { LoggedInGuard } from 'ngx-auth-firebaseui';
import { SettingsComponent } from './settings/settings.component';
import { PurchaseComponent} from './purchase/purchase.component';
import { GetstartedComponent } from './getstarted/getstarted.component';
import { LoginComponent } from './login/login.component';
import { ConfigService } from './config.service';


const routes: Routes = [
  { path: '', component: AboutComponent },
  {
    path: 'monitor', component: MonitorComponent,
    resolve: {
      data: ConfigService
    },
    canActivate: [LoggedInGuard]
  },
  {
    path: 'settings', component: SettingsComponent,
    resolve: {
      data: ConfigService
    },
    canActivate: [LoggedInGuard]
  },
  { path: 'about', component: AboutComponent },
  { path: 'purchase', component: PurchaseComponent },
  { path: 'getstarted', component: GetstartedComponent },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    ConfigService
  ]
})
export class AppRoutingModule { }
