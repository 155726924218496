import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private user: any;
  uid: string;

  constructor() { }

  public setUser(user: any) {
      this.user = user;
  }

  public getUser() {
    return this.user;
  }

  public setUid(uid: string) {
    this.uid = uid;
    localStorage.setItem('uid', uid); // save uid to local storage.
  }

  public getUid() {
    if (this.uid) {
      return this.uid;
    } else {
      const uid = localStorage.getItem('uid');
      this.uid = uid;
      return this.uid;
    }

  }
}
