import { Component, OnInit, Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { LoginService } from '../login.service';
import { MatRadioChange } from '@angular/material/radio';
import { AngularFirestore, AngularFirestoreDocument, DocumentData } from '@angular/fire/firestore';
import { TempDevice } from '../temp-device';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MessagingService } from '../messaging.service';
import { DataService } from '../data.service';
import { AlertConfig } from '../alert-config';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

@Injectable()
export class SettingsComponent implements OnInit {
  private fahrenheit: boolean;
  public alertConfig: AlertConfig;
  alertsDoc: AngularFirestoreDocument;
  alerts: Observable<DocumentData>;

  constructor(private afs: AngularFirestore, private loginService: LoginService,
              private configService: ConfigService, private msgService: MessagingService, private dataService: DataService) { }

  ngOnInit() {
    console.log('Settings init...');
    this.setFahrenheit();
    const uid = this.loginService.getUid();
    this.msgService.receiveMessage();
  }

  changeTempUnits($event: MatRadioChange) {
    console.log($event);
    // this.configService.settingsDoc.update({tempUnit: $event.value});
    // this.configService.setTempUnit($event.value);
    this.convertAlerts();
  }

  public changeDevice(device: TempDevice) {
    console.log(device.deviceId);

    this.configService.getAlertConfig(device.deviceId).pipe(take(1)).subscribe(
      {
        next: item => {
          try {
            console.log(item);
            this.configService.alertConfig = item;
            this.configService.setCurrentDevice(device.deviceId);
          } catch (e) {
            console.log('Caught error:' + e);
          }
        },
        error(msg) {
          console.log('Error Getting alerts: ', msg);
        }
      }
    );
  }

  public updateAlerts() {
    console.log('Updating alerts...');
    // this.configService.alertsDoc.set(this.configService.alertConfig);
    this.configService.alertsDoc.update({
      displayName: this.configService.alertConfig.displayName,
      units: this.configService.alertConfig.units,
      'probes.Probe1.active': this.configService.alertConfig.probes.Probe1.active,
      'probes.Probe1.max': this.configService.alertConfig.probes.Probe1.max,
      'probes.Probe1.min': this.configService.alertConfig.probes.Probe1.min,
      'probes.Probe1.name': this.configService.alertConfig.probes.Probe1.name,
      'probes.Probe1.kalman': this.configService.alertConfig.probes.Probe1.kalman,
      'probes.Probe1.kalmanQ': this.configService.alertConfig.probes.Probe1.kalmanQ,
      'probes.Probe1.kalmanR': this.configService.alertConfig.probes.Probe1.kalmanR,
      'probes.Probe2.active': this.configService.alertConfig.probes.Probe2.active,
      'probes.Probe2.max': this.configService.alertConfig.probes.Probe2.max,
      'probes.Probe2.min': this.configService.alertConfig.probes.Probe2.min,
      'probes.Probe2.name': this.configService.alertConfig.probes.Probe2.name,
      'probes.Probe2.kalman': this.configService.alertConfig.probes.Probe2.kalman,
      'probes.Probe2.kalmanQ': this.configService.alertConfig.probes.Probe2.kalmanQ,
      'probes.Probe2.kalmanR': this.configService.alertConfig.probes.Probe2.kalmanR
    });
  }

  private convertAlerts() {
    const tempUnit = this.configService.getTempUnit();

    if (tempUnit === 'F') {
      this.configService.alertConfig.probes.Probe1.max = this.dataService.cToF(this.configService.alertConfig.probes.Probe1.max);
      this.configService.alertConfig.probes.Probe1.min = this.dataService.cToF(this.configService.alertConfig.probes.Probe1.min);
      this.configService.alertConfig.probes.Probe2.max = this.dataService.cToF(this.configService.alertConfig.probes.Probe2.max);
      this.configService.alertConfig.probes.Probe2.min = this.dataService.cToF(this.configService.alertConfig.probes.Probe2.min);
    } else {
      this.configService.alertConfig.probes.Probe1.max = this.dataService.fToC(this.configService.alertConfig.probes.Probe1.max);
      this.configService.alertConfig.probes.Probe1.min = this.dataService.fToC(this.configService.alertConfig.probes.Probe1.min);
      this.configService.alertConfig.probes.Probe2.max = this.dataService.fToC(this.configService.alertConfig.probes.Probe2.max);
      this.configService.alertConfig.probes.Probe2.min = this.dataService.fToC(this.configService.alertConfig.probes.Probe2.min);
    }
    this.configService.alertConfig.units = tempUnit;
    this.updateAlerts();
  }

  public getFahrenheit(): boolean {
    return this.fahrenheit;
  }

  public setFahrenheit() {
    this.fahrenheit = (this.configService.getTempUnit() === 'F') ? true : false;
  }
}
